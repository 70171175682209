import { ResetPasswordActionTypes as Types } from 'actions/types';

const initialState = {
    crmResetPasswordForm: {
        isLoading: false,
        isSucceed: false,
        errorMessage: '',
    },
    crmResetPasswordConfirmForm: {
        isLoading: false,
        isSucceed: false,
        errorMessage: '',
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.CRM_RESET_PASSWORD_FETCH:
            state.crmResetPasswordForm.isLoading = true;
            state.crmResetPasswordForm.isSucceed = false;
            state.crmResetPasswordForm.errorMessage = '';
            return Object.assign({}, state, {});
        case Types.CRM_RESET_PASSWORD_SUCCEED:
            state.crmResetPasswordForm.isLoading = false;
            state.crmResetPasswordForm.isSucceed = true;
            return Object.assign({}, state, {});
        case Types.CRM_RESET_PASSWORD_FAILED:
            state.crmResetPasswordForm.isLoading = false;
            state.crmResetPasswordForm.isSucceed = false;
            state.crmResetPasswordForm.errorMessage = action.payload;
            return Object.assign({}, state, {});
        case Types.CLEAR_CRM_RESET_PASSWORD:
            state.crmResetPasswordForm.isLoading = false;
            state.crmResetPasswordForm.isSucceed = false;
            state.crmResetPasswordForm.errorMessage = '';
            return Object.assign({}, state, {});

        case Types.CRM_RESET_PASSWORD_CONFIRM_FETCH:
            state.crmResetPasswordConfirmForm.isLoading = true;
            state.crmResetPasswordConfirmForm.isSucceed = false;
            state.crmResetPasswordConfirmForm.errorMessage = '';
            return Object.assign({}, state, {});
        case Types.CRM_RESET_PASSWORD_CONFIRM_SUCCEED:
            state.crmResetPasswordConfirmForm.isLoading = false;
            state.crmResetPasswordConfirmForm.isSucceed = true;
            return Object.assign({}, state, {});
        case Types.CRM_RESET_PASSWORD_CONFIRM_FAILED:
            state.crmResetPasswordConfirmForm.isLoading = false;
            state.crmResetPasswordConfirmForm.isSucceed = false;
            state.crmResetPasswordConfirmForm.errorMessage = action.payload;
            return Object.assign({}, state, {});
        case Types.CLEAR_CRM_RESET_PASSWORD_CONFIRM:
            state.crmResetPasswordConfirmForm.isLoading = false;
            state.crmResetPasswordConfirmForm.isSucceed = false;
            state.crmResetPasswordConfirmForm.errorMessage = '';
            return Object.assign({}, state, {});

        default:
            return state;
    }
};
