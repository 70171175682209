import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Import Constants
import { BrowserStorageKeys } from 'constants/browserStorage';
import { RoutesList } from 'routes';

const { CRM_SIGN_IN, SIGN_IN_SSO, CRM_RESET_PASSWORD, CRM_RESET_PASSWORD_CONFIRM } = RoutesList;

const PrivateRoute = ({ path, titleKey, component: Component }) => {
    const { pathname, search } = useLocation();
    const { isLoggedIn } = useSelector((state) => state.auth);

    const token = localStorage.getItem(BrowserStorageKeys.accessToken);
    const isCRMSignIn = localStorage.getItem(BrowserStorageKeys.isCRMSignIn) === 'true';

    if (isLoggedIn) {
        return (
            <Route path={path}>
                <Component titleKey={titleKey} />
            </Route>
        );
    } else if (isLoggedIn !== null || isEmpty(token)) {
        if (
            isEmpty(token) &&
            ![CRM_SIGN_IN.path, CRM_RESET_PASSWORD.path, CRM_RESET_PASSWORD_CONFIRM.path].some((path) =>
                pathname.includes(path),
            )
        ) {
            localStorage.setItem(BrowserStorageKeys.directUrl, pathname + search);
        }

        if (isCRMSignIn) {
            return <Redirect to={`/${CRM_SIGN_IN.path}`} />;
        }

        return <Redirect to={`/${SIGN_IN_SSO.path}`} />;
    }
};

PrivateRoute.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.object.isRequired,
    titleKey: PropTypes.string,
};

export default memo(PrivateRoute);
