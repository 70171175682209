const RoutesList = {
    // Auth
    SIGN_IN_SSO: { path: 'signin', name: 'SignInSSOPage' },
    CRM_SIGN_IN: { path: 'crm/signin', name: 'CRMSignInPage' },
    CRM_RESET_PASSWORD: { path: 'crm/reset-password', name: 'ResetPasswordEmailPage' },
    CRM_RESET_PASSWORD_CONFIRM: { path: 'crm/reset-password/confirm', name: 'ResetPasswordConfirmPage' },

    // HomePage
    HOMEPAGE: { path: 'homepage', name: 'HomePage' },

    // Dashboard
    DASHBOARD: { path: 'dashboard', name: 'Dashboard' },

    // Segments
    SEGMENT_LIST: { path: 'segment/list', name: 'Segments' },
    SEGMENT_STATIC_CREATE: { path: 'segment/static/create', name: 'Segment' },
    SEGMENT_NON_CUSTOMER_CREATE: { path: 'segment/non-customer/create', name: 'Segment' },
    SEGMENT_DYNAMIC_CREATE: { path: 'segment/dynamic/create', name: 'Segment' },
    SEGMENT_DYNAMIC_EDIT: { path: 'segment/dynamic/:segmentId/edit', name: 'Segment' },
    SEGMENT_DYNAMIC_CLONE: { path: 'segment/dynamic/:segmentId/clone', name: 'Segment' },
    SEGMENT_DYNAMIC_PREDEFINED: { path: 'segment/dynamic/:segmentId/predefined', name: 'Segment' },
    SEGMENT_COMPOSITE_CREATE: { path: 'segment/composite/create', name: 'Segment' },
    SEGMENT_COMPOSITE_EDIT: { path: 'segment/composite/:segmentId/edit', name: 'Segment' },
    SEGMENT_COMPOSITE_CLONE: { path: 'segment/composite/:segmentId/clone', name: 'Segment' },

    // Campaigns
    CAMPAIGN_LIST: { path: 'campaign/list', name: 'Campaigns' },
    CAMPAIGN_CREATE: { path: 'campaign/create', name: 'Campaign' },
    CAMPAIGN_EDIT: { path: 'campaign/:campaignId/edit', name: 'Campaign' },
    CAMPAIGN_CLONE: { path: 'campaign/:campaignId/clone', name: 'Campaign' },
    CAMPAIGN_REPORT: { path: 'campaign/:campaignId/report', name: 'Campaign' },

    // Templates
    TEMPLATE_LIST: { path: 'template/list', name: 'Templates' },
    TEMPLATE_CREATE_EMAIL: { path: 'template/email/create', name: 'Template' },
    TEMPLATE_CREATE_EDIT: { path: 'template/email/:templateId/edit', name: 'Template' },
    TEMPLATE_CREATE_CLONE: { path: 'template/email/:templateId/clone', name: 'Template' },
    TEMPLATE_PREDEFINED_EMAIL: { path: 'template/email/:templateId/predefined', name: 'Template' },
    TEMPLATE_CREATE_SMS: { path: 'template/sms/create', name: 'Template' },
    TEMPLATE_CREATE_HOORY: { path: 'template/hoory/create', name: 'Template' },
    TEMPLATE_CREATE_FASTEX_NOTIFICATION: { path: 'template/fastex-notification/create', name: 'Template' },
    TEMPLATE_CREATE_PUSH_NOTIFICATION: { path: 'template/push-notification/create', name: 'Template' },
    TEMPLATE_CREATE_INTERNAL_MESSAGE: { path: 'template/internal-message/create', name: 'Template' },
    TEMPLATE_CREATE_SOCIAL_TELEGRAM: { path: 'template/social-telegram/create', name: 'Template' },
    TEMPLATE_CREATE_SOCIAL_VIBER: { path: 'template/social-viber/create', name: 'Template' },
    TEMPLATE_CREATE_POP_UP_NOTIFICATION: { path: 'template/pop-up-notification/create', name: 'Template' },
    TEMPLATE_CREATE_DYNAMIC_POP_UP: {
        path: 'template/dynamic-pop-up/create',
        name: 'Template',
    },
    TEMPLATE_CREATE_CMS_POP_UP_NOTIFICATION: { path: 'template/cms-pop-up-notification/create', name: 'Template' },
    TEMPLATE_CLONE_EMAIL: { path: 'template/email/:templateId/clone', name: 'Template' },
    TEMPLATE_CLONE_DYNAMIC_POP_UP: {
        path: 'template/dynamic-pop-up/:templateId/clone',
        name: 'Template',
    },
    TEMPLATE_CLONE_SMS: { path: 'template/sms/:templateId/clone', name: 'Template' },
    TEMPLATE_CLONE_HOORY: { path: 'template/hoory/:templateId/clone', name: 'Template' },
    TEMPLATE_CLONE_FASTEX_NOTIFICATION: { path: 'template/fastex-notification/:templateId/clone', name: 'Template' },
    TEMPLATE_CLONE_PUSH_NOTIFICATION: { path: 'template/push-notification/:templateId/clone', name: 'Template' },
    TEMPLATE_CLONE_POP_UP_NOTIFICATION: { path: 'template/pop-up-notification/:templateId/clone', name: 'Template' },
    TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION: {
        path: 'template/cms-pop-up-notification/:templateId/clone',
        name: 'Template',
    },
    TEMPLATE_PREDEFINED_POP_UP_NOTIFICATION: {
        path: 'template/pop-up-notification/:templateId/predefined',
        name: 'Template',
    },
    TEMPLATE_PREDEFINED_DYNAMIC_POP_UP: {
        path: 'template/dynamic-pop-up/:templateId/predefined',
        name: 'Template',
    },
    TEMPLATE_PREDEFINED_SMS: { path: 'template/sms/:templateId/predefined', name: 'Template' },
    TEMPLATE_PREDEFINED_HOORY: { path: 'template/hoory/:templateId/predefined', name: 'Template' },
    TEMPLATE_PREDEFINED_FASTEX_NOTIFICATION: {
        path: 'template/fastex-notification/:templateId/predefined',
        name: 'Template',
    },
    TEMPLATE_CLONE_INTERNAL_MESSAGE: { path: 'template/internal-message/:templateId/clone', name: 'Template' },
    TEMPLATE_PREDEFINED_INTERNAL_MESSAGE: {
        path: 'template/internal-message/:templateId/predefined',
        name: 'Template',
    },
    TEMPLATE_CLONE_SOCIAL_TELEGRAM: { path: 'template/social-telegram/:templateId/clone', name: 'Template' },
    TEMPLATE_CLONE_SOCIAL_VIBER: { path: 'template/social-viber/:templateId/clone', name: 'Template' },
    TEMPLATE_PREDEFINED_SOCIAL_TELEGRAM: { path: 'template/social-telegram/:templateId/predefined', name: 'Template' },
    TEMPLATE_PREDEFINED_PUSH_NOTIFICATION: {
        path: 'template/push-notification/:templateId/predefined',
        name: 'Template',
    },
    TEMPLATE_EDIT_EMAIL: { path: 'template/email/:templateId/edit', name: 'Template' },
    TEMPLATE_EDIT_SMS: { path: 'template/sms/:templateId/edit', name: 'Template' },
    TEMPLATE_EDIT_HOORY: { path: 'template/hoory/:templateId/edit', name: 'Template' },
    TEMPLATE_EDIT_FASTEX_NOTIFICATION: { path: 'template/fastex-notification/:templateId/edit', name: 'Template' },
    TEMPLATE_EDIT_INTERNAL_MESSAGE: { path: 'template/internal-message/:templateId/edit', name: 'Template' },
    TEMPLATE_EDIT_PUSH_NOTIFICATION: { path: 'template/push-notification/:templateId/edit', name: 'Template' },
    TEMPLATE_EDIT_POP_UP_NOTIFICATION: { path: 'template/pop-up-notification/:templateId/edit', name: 'Template' },
    TEMPLATE_EDIT_DYNAMIC_POP_UP: {
        path: 'template/dynamic-pop-up/:templateId/edit',
        name: 'Template',
    },
    TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION: {
        path: 'template/cms-pop-up-notification/:templateId/edit',
        name: 'Template',
    },
    TEMPLATE_EDIT_SOCIAL_TELEGRAM: { path: 'template/social-telegram/:templateId/edit', name: 'Template' },
    TEMPLATE_EDIT_SOCIAL_VIBER: { path: 'template/social-viber/:templateId/edit', name: 'Template' },

    // Reports
    REPORT_LIST: { path: 'report/list', name: 'Reports' },
    REPORT_CREATE: { path: 'report/create', name: 'Report' },
    REPORT_EDIT: { path: 'report/:reportId/edit', name: 'Report' },
    REPORT_CLONE: { path: 'report/:reportId/clone', name: 'Report' },
    REPORT_EXECUTED_LIST: { path: 'report/executed/:reportType/:reportId/list', name: 'Report' },
    REPORT_SCHEDULE: { path: 'report/schedule/:reportType/:reportId', name: 'Report' },
    REPORT_EXECUTE: { path: 'report/execute/:reportType/:reportId', name: 'Report' },
    CONVERT_TO_REPORT: { path: 'report/:segmentId/convert', name: 'Report' },
    REPORT_COMPOSITE_CREATE: { path: 'report/composite/create', name: 'Report' },
    REPORT_COMPOSITE_EDIT: { path: 'report/composite/:reportId/edit', name: 'Report' },
    REPORT_COMPOSITE_CLONE: { path: 'report/composite/:reportId/clone', name: 'Report' },
    REPORT_CUSTOMER_JOURNEY_CREATE: { path: 'report/customer-journey/create', name: 'Report' },
    REPORT_CUSTOMER_JOURNEY_EDIT: { path: 'report/customer-journey/:reportId/edit', name: 'Report' },
    REPORT_CUSTOMER_JOURNEY_CLONE: { path: 'report/customer-journey/:reportId/clone', name: 'Report' },

    // Realtime Journeys
    REALTIME_PROMOTIONS_LIST: { path: 'realtime/list', name: 'RealtimePromotionsList' },
    REALTIME_JOURNEY_CREATE: { path: 'realtime/journey/create', name: 'Journey' },
    REALTIME_JOURNEY_EDIT: { path: 'realtime/journey/:workflowId/edit', name: 'Journey' },
    REALTIME_JOURNEY_CLONE: { path: 'realtime/journey/:workflowId/clone', name: 'Journey' },
    REALTIME_JOURNEY_PREDEFINED: { path: 'realtime/journey/:workflowId/predefined', name: 'Journey' },
    REALTIME_JOURNEY_VIEW: { path: 'realtime/journey/:workflowId/view', name: 'Journey' },
    REALTIME_JOURNEY_REPORT: { path: 'realtime/journey/:workflowId/report', name: 'Journey' },
    REALTIME_JOURNEY_VISUALIZATION_CREATE: {
        path: 'realtime/journey/visualization/:workflowId/create',
        name: 'Journey',
    },
    REALTIME_JOURNEY_VISUALIZATION_EDIT: {
        path: 'realtime/journey/visualization/:workflowId/edit',
        name: 'Journey',
    },

    // Realtime Action Campaigns
    REALTIME_ACTION_CREATE: { path: 'realtime/action/create', name: 'RealtimeAction' },
    REALTIME_ACTION_EDIT: { path: 'realtime/action/:workflowId/edit', name: 'RealtimeAction' },
    REALTIME_ACTION_CLONE: {
        path: 'realtime/action/:workflowId/clone',
        name: 'RealtimeAction',
    },
    REALTIME_ACTION_VIEW: { path: 'realtime/action/:workflowId/view', name: 'RealtimeAction' },
    REALTIME_ACTION_REPORT: { path: 'realtime/action/:workflowId/report', name: 'RealtimeAction' },

    // Analytics
    ANALYTICS: { path: 'analytics', name: 'Analytics' },

    // Settings
    SETTINGS: { path: 'settings', name: 'Settings' },
    // Communication
    COMMUNICATION: { path: 'communication', name: 'Communication' },

    // Configuration
    CONFIGURATION: { path: 'configuration', name: 'Configuration' },
    CONFIGURATION_NOTIFICATIONS: { path: 'configuration/notifications', name: 'Configuration' },
    CONFIGURATION_PROVIDERS: { path: 'configuration/providers', name: 'Configuration' },
    CONFIGURATION_AWARDS: { path: 'configuration/awards', name: 'Awards' },
    CONFIGURATION_TEMPLATE_LANGUAGES: { path: 'configuration/template-languages', name: 'Configuration' },
    CONFIGURATION_GENERAL: { path: 'configuration/general', name: 'General' },

    // Notifications
    NOTIFICATION: { path: 'configuration/:eventId/notification', name: 'Notification' },

    // Administration
    ADMINISTRATION: { path: 'administration', name: 'Administration' },
    ADMINISTRATION_PARTNER_LIST: { path: 'administration/partner/list', name: 'Administration' },
    ADMINISTRATION_CJ_BLOCK_MANAGEMENT: { path: 'administration/cj-block-management', name: 'Administration' },
    ADMINISTRATION_PROVIDERS: { path: 'administration/providers', name: 'Administration' },
    ADMINISTRATION_SETTINGS: { path: 'administration/settings', name: 'Administration' },
    ADMINISTRATION_DATA_WAREHOUSE: { path: 'administration/data-warehouse', name: 'Administration' },
    ADMINISTRATION_CLASSIFIERS: { path: 'administration/classifiers', name: 'Administration' },
    ADMINISTRATION_MENUITEMS: { path: 'administration/menu-items', name: 'Administration' },

    // Clients
    CLIENT_VIEW: { path: 'client/:clientId/view', name: 'Client' },
    CLIENT_LIST: { path: 'client/list', name: 'Clients' },
};

export default RoutesList;
