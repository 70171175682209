import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Button, ExtendedInput } from '@geneui/components';
// Import Constants
import { RoutesList } from 'routes';
import { AuthIcons, l } from 'constants/common';
import { PASSWORD_VALIDATION_REGEXP } from 'constants/regexp';
// Import Hooks
import { useGoToRoute } from 'hooks';

const { CRM_SIGN_IN } = RoutesList;

const selectResetPassword = (state) => state.resetPassword;

const ResetPasswordConfirmForm = ({ confirmResetPasswordHandler }) => {
    const { t } = useTranslation();
    const { goToRoute, getRoutePath } = useGoToRoute();
    const signInPagePath = getRoutePath(CRM_SIGN_IN);

    const { crmResetPasswordConfirmForm } = useSelector(selectResetPassword);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setConfirmIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => setIsPasswordVisible((prev) => !prev);
    const toggleConfirmPasswordVisibility = () => setConfirmIsPasswordVisible((prev) => !prev);

    const onSubmitHandler = ({ newPassword }) => {
        confirmResetPasswordHandler({ password: newPassword });
    };

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validateOnChange: true,
        validateOnBlur: false,
        validateOnMount: false,
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .required(t(l.CannotBeEmpty, { fieldName: t(l.NewPassword) }))
                .matches(PASSWORD_VALIDATION_REGEXP, t(l.PasswordValidation)),
            confirmPassword: Yup.string()
                .required(t(l.CannotBeEmpty, { fieldName: t(l.ConfirmPassword) }))
                .oneOf([Yup.ref('newPassword'), null], t(l.PasswordsMustMatch)),
        }),
        onSubmit: onSubmitHandler,
    });

    const { values, errors, touched, isValid, dirty, handleChange, handleBlur, handleSubmit } = formik;

    const handleGoToLogInPageClick = (e) => {
        e.preventDefault();
        goToRoute(CRM_SIGN_IN);
    };

    return (
        <form onSubmit={handleSubmit} className="crm-auth-form-card">
            <h2 className="crm-auth-form-line crm-auth-form-title">{t(l.CRM)}</h2>

            <h3 className="crm-auth-form-line crm-auth-form-subtitle">{t(l.ResetPassword)}</h3>

            <ExtendedInput
                className="crm-auth-form-line crm-auth-form-input"
                name="newPassword"
                type={isPasswordVisible ? 'text' : 'password'}
                autoComplete="new-password"
                cornerRadius="smooth-radius"
                isValid={touched.newPassword ? !errors.newPassword : undefined}
                labelAppearance="swap"
                label={`${t(l.NewPassword)}*`}
                placeholder={`${t(l.NewPassword)}*`}
                errorText={errors.newPassword}
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                endAdornment={
                    <Button
                        type="button"
                        color="default"
                        appearance="minimal"
                        icon={isPasswordVisible ? AuthIcons.Inactivate : AuthIcons.Activate}
                        onClick={togglePasswordVisibility}
                    />
                }
            />
            <ExtendedInput
                className="crm-auth-form-line crm-auth-form-input"
                name="confirmPassword"
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                autoComplete="new-password"
                cornerRadius="smooth-radius"
                isValid={touched.confirmPassword ? !errors.confirmPassword : undefined}
                labelAppearance="swap"
                label={`${t(l.ConfirmPassword)}*`}
                placeholder={`${t(l.ConfirmPassword)}*`}
                errorText={errors.confirmPassword}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                endAdornment={
                    <Button
                        type="button"
                        color="default"
                        appearance="minimal"
                        icon={isConfirmPasswordVisible ? AuthIcons.Inactivate : AuthIcons.Activate}
                        onClick={toggleConfirmPasswordVisibility}
                    />
                }
            />

            <div className="crm-auth-form-line">
                <Button
                    type="submit"
                    size="big"
                    flexibility="full-width"
                    disabled={!(dirty && isValid) || crmResetPasswordConfirmForm.isLoading}
                    loading={crmResetPasswordConfirmForm.isLoading}
                >
                    {t(l.ChangePassword)}
                </Button>
            </div>

            <div className="crm-auth-form-line crm-auth-form-back-button">
                <a
                    href={signInPagePath}
                    className="crm-auth-form-link crm-auth-form-link-back-button tittle-text ellipsis-text"
                    onClick={handleGoToLogInPageClick}
                >
                    {t(l.GoToLogInPage)}
                </a>
            </div>
        </form>
    );
};

ResetPasswordConfirmForm.propTypes = {
    confirmResetPasswordHandler: PropTypes.func.isRequired,
};

export default memo(ResetPasswordConfirmForm);
