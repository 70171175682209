import { ResetPasswordActionTypes as Types } from './types';

const crmResetPasswordFetch = (status) => ({
    type: Types.CRM_RESET_PASSWORD_FETCH,
    payload: status,
});

const crmResetPasswordSucceed = (status) => ({
    type: Types.CRM_RESET_PASSWORD_SUCCEED,
    payload: status,
});

const crmResetPasswordFailed = (error) => ({
    type: Types.CRM_RESET_PASSWORD_FAILED,
    payload: error,
});

const clearCRMResetPassword = () => ({
    type: Types.CLEAR_CRM_RESET_PASSWORD,
});

const crmResetPasswordConfirmFetch = (status) => ({
    type: Types.CRM_RESET_PASSWORD_CONFIRM_FETCH,
    payload: status,
});

const crmResetPasswordConfirmSucceed = (status) => ({
    type: Types.CRM_RESET_PASSWORD_CONFIRM_SUCCEED,
    payload: status,
});

const crmResetPasswordConfirmFailed = (error) => ({
    type: Types.CRM_RESET_PASSWORD_CONFIRM_FAILED,
    payload: error,
});

const clearCRMResetPasswordConfirm = () => ({
    type: Types.CLEAR_CRM_RESET_PASSWORD_CONFIRM,
});

export default {
    crmResetPasswordFetch,
    crmResetPasswordSucceed,
    crmResetPasswordFailed,
    clearCRMResetPassword,

    crmResetPasswordConfirmFetch,
    crmResetPasswordConfirmSucceed,
    crmResetPasswordConfirmFailed,
    clearCRMResetPasswordConfirm,
};
