import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Button, ExtendedInput } from '@geneui/components';
// Import Constants
import { RoutesList } from 'routes';
import { AuthIcons, l } from 'constants/common';
import { EMAIL_VALIDATION_REGEXP } from 'constants/regexp';

const { CRM_RESET_PASSWORD } = RoutesList;

const selectSignIn = (state) => state.signIn;

const CRMSignInForm = ({ signInHandler }) => {
    const { t } = useTranslation();
    const { crmSignInForm } = useSelector(selectSignIn);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => setIsPasswordVisible((prevState) => !prevState);

    function onSubmitHandler(newValues) {
        signInHandler({
            Login: newValues.email,
            Password: newValues.password,
        });
    }

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validateOnChange: true,
        validationSchema: Yup.object({
            email: Yup.string()
                .required(t(l.CannotBeEmpty, { fieldName: t(l.Email) }))
                .matches(EMAIL_VALIDATION_REGEXP, t(l.EmailIsNotInCorrectFormat)),

            password: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.Password) })),
        }),
        onSubmit: onSubmitHandler,
    });

    return (
        <form onSubmit={handleSubmit} className="crm-auth-form-card">
            <h2 className="crm-auth-form-line crm-auth-form-title">{t(l.CRM)}</h2>

            <h3 className="crm-auth-form-line crm-auth-form-subtitle">{t(l.SignIn)}</h3>

            <ExtendedInput
                className="crm-auth-form-line"
                name="email"
                type="text"
                autoComplete="email"
                cornerRadius="smooth-radius"
                isValid={touched.email ? !errors.email : undefined}
                labelAppearance="swap"
                label={`${t(l.Email)}*`}
                placeholder={`${t(l.Email)}*`}
                errorText={errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <ExtendedInput
                className="crm-auth-form-line"
                name="password"
                type={isPasswordVisible ? 'text' : 'password'}
                autoComplete="password"
                cornerRadius="smooth-radius"
                isValid={touched.password ? !errors.password : undefined}
                labelAppearance="swap"
                label={`${t(l.Password)}*`}
                placeholder={`${t(l.Password)}*`}
                errorText={errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                endAdornment={
                    <Button
                        type="button"
                        color="default"
                        appearance="minimal"
                        icon={isPasswordVisible ? AuthIcons.Inactivate : AuthIcons.Activate}
                        onClick={togglePasswordVisibility}
                    />
                }
            />

            <Link
                to={`/${CRM_RESET_PASSWORD.path}`}
                className="crm-auth-form-line crm-auth-form-link tittle-text ellipsis-text"
            >
                {t(l.ForgotPassword)}
            </Link>

            <div className="crm-auth-form-line">
                <Button
                    type="submit"
                    size="big"
                    flexibility="full-width"
                    loading={crmSignInForm.isLoading}
                    disabled={crmSignInForm.isLoading}
                >
                    {t(l.SignIn)}
                </Button>
            </div>
        </form>
    );
};

CRMSignInForm.propTypes = {
    signInHandler: PropTypes.func.isRequired,
};

export default memo(CRMSignInForm);
